import React from 'react'
import Container from '../components/container'

export default function Contact() {
  return (
    <Container>
      Email me: astericky@gmail.com
    </Container>
  )
}
